import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal/Modal';
import { BUTTON_VARIANT } from 'constants/interfaces';

import type IDeleteModal from './interfaces/IDeleteModal';

export default function DeleteModal({
  open,
  onReject,
  isLoading,
  onConfirm,
  name,
}: IDeleteModal) {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      title={t('common.deleteValueChainMap', { name })}
      description={t('common.thisActionCannotBeUndone')}
      closeTitle={t('common.cancel')}
      confirmTitle={t('common.delete')}
      confirmVariant={BUTTON_VARIANT.error}
      onReject={onReject}
      onConfirm={onConfirm}
      isLoading={isLoading}
    />
  );
}
