import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ActionIcon } from 'assets/icons/action.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';
import IconButton from 'components/IconButton/IconButton';
import RenameModal from 'components/RenameModal/RenameModal';
import Tooltip from 'components/Tooltip/Tooltip';
import useDropdownDirection from 'hooks/useDropdownDirection';
import useOutsideClick from 'hooks/useOutsideClick';
import DeleteModal from 'pages/CompanyAdminPages/ValueChainMap/components/DeleteModal/DeleteModal';

import ArchiveModal from '../ArchiveModal/ArchiveModal';
import MoreActionsDropdown from './components/MoreActionsDropdown';

import type IValueChainMapTableActions from '../../interfaces/IValueChainActions';

import styles from './scss/ValueChainMapTableActions.module.scss';

function ValueChainMapTableActions({
  onHandleEdit,
  isArchived,
  onRenameValueChain,
  valueChainItemId,
  onDeleteValueChain,
  valueChainName,
  tableRef,
  onArchiveValueChain,
  onDeArchiveValueChain,
  onDublicateValueChain,
}: IValueChainMapTableActions) {
  const { t } = useTranslation();
  const [isMoreActionsModalOpen, setIsMoreActionsModalOpen] = useState(false);
  const [isRenameModalOpen, setRenameModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isArchiveModalOpen, setArchiveModalOpen] = useState(false);
  const [isDublicateModalOpen, setDublicateModalOpen] = useState(false);
  const modalRef = useOutsideClick(() => setIsMoreActionsModalOpen(false));

  const { dropdownDirection } = useDropdownDirection({ isMoreActionsModalOpen, modalRef, tableRef });

  const renameHandler = (name: string) => {
    onRenameValueChain(valueChainItemId, name);
    setRenameModalOpen(false);
  };

  const deleteHandler = () => {
    onDeleteValueChain();
    setDeleteModalOpen(false);
  };

  const archiveHandler = (reason: string) => {
    onArchiveValueChain(valueChainItemId, reason);
    setArchiveModalOpen(false);
  };

  const deArchiveHandler = () => {
    setIsMoreActionsModalOpen(false);
    onDeArchiveValueChain(valueChainItemId);
  };

  const dublicateHandler = (name: string) => {
    onDublicateValueChain(valueChainItemId, name);
    setDublicateModalOpen(false);
  };

  const openRenameModal = () => {
    setRenameModalOpen(true);
    setIsMoreActionsModalOpen(false);
  };

  const openDublicateModal = () => {
    setDublicateModalOpen(true);
    setIsMoreActionsModalOpen(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {!isArchived ? (
          <>
            <Tooltip hint={t('valueChainMap.edit')}>
              <IconButton
                icon={<PenIcon />}
                onClick={onHandleEdit}
                className={styles.iconButton}
              />
            </Tooltip>
            <Tooltip hint={t('valueChainMap.delete')}>
              <IconButton
                icon={<DeleteIcon />}
                onClick={() => setDeleteModalOpen(true)}
                className={styles.iconButton}
              />
            </Tooltip>
          </>
        ) : null}
        <div ref={modalRef}>
          <IconButton
            icon={<ActionIcon />}
            onClick={() => setIsMoreActionsModalOpen(!isMoreActionsModalOpen)}
            className={styles.iconButton}
          />
          {isMoreActionsModalOpen ? (
            <MoreActionsDropdown
              isArchived={isArchived}
              setRenameModalOpen={openRenameModal}
              dropdownDirection={dropdownDirection}
              setArchiveModalOpen={setArchiveModalOpen}
              deArchiveHandler={deArchiveHandler}
              dublicateHandler={openDublicateModal}
            />
          ) : null}
        </div>
      </div>
      <RenameModal
        onSubmit={renameHandler}
        onReject={() => setRenameModalOpen(false)}
        open={isRenameModalOpen}
        name={valueChainName}
      />
      <RenameModal
        onSubmit={dublicateHandler}
        onReject={() => setDublicateModalOpen(false)}
        open={isDublicateModalOpen}
        name={`${t('valueChainMap.copyOf')} ${valueChainName}`}
      />
      <DeleteModal
        open={isDeleteModalOpen}
        onReject={() => setDeleteModalOpen(false)}
        isLoading={false}
        name={valueChainName}
        onConfirm={deleteHandler}
      />
      <ArchiveModal
        open={isArchiveModalOpen}
        onReject={() => setArchiveModalOpen(false)}
        isLoading={false}
        onSubmit={archiveHandler}
      />
    </div>
  );
}

export default ValueChainMapTableActions;
