const deactivationInfoMock = {
  date: '2021-09-01',
  reason: 'Some reason',
  initiator: {
    firstName: 'John',
    lastName: 'Doe',
  },
};

export default deactivationInfoMock;
