import React from 'react';
import { useTranslation } from 'react-i18next';
import { Handle, Position } from '@xyflow/react';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as ProfitCenterIcon } from 'assets/icons/profitCenter.svg';
import { clsx } from 'clsx';

import IconButton from '../../../../../../components/IconButton/IconButton';
import { DEFAULT_PROFIT_CENTER_PERCENTAGE } from '../../../../../../constants/general';
import CustomNodeItem from './CustomNodeItem';

import type { ICustomNode } from '../interfaces/ICustomNode';

import styles from './sass/CustomNode.module.scss';

function CustomNode({ data }: ICustomNode) {
  const { t } = useTranslation();

  const { isDisabled } = data;

  return (
    <div className={clsx(styles.customNode, {
      [styles.dragTarget]: data.isDragTarget === true,
    })}
    >
      <div className={clsx(styles.header, styles[data?.ownership?.replace(/\s+/g, '')], {
        [styles.disabled]: isDisabled,
      })}
      >
        <div className={styles.titleWrapper}>
          <ProfitCenterIcon width={18} height={18} />
          <p className={clsx({ [styles.disabled]: isDisabled })}>{data.name}</p>
        </div>
        <div className={styles.activeWrapper}>
          <div className={clsx(styles.percentage, styles[data?.ownership?.replace(/\s+/g, '')], {
            [styles.disabled]: isDisabled,
          })}
          >
            <span className={styles.percentageText}>{DEFAULT_PROFIT_CENTER_PERCENTAGE}</span>
          </div>
          {isDisabled ? (
            <IconButton
              className={styles.info}
              icon={<InfoIcon />}
              onClick={() => data.onDisabledInfoPress(data)}
              isWarning
            />
          ) : null}
          <input
            type="checkbox"
            id="isActiveProfitCenter"
            checked={!isDisabled}
            onChange={() => data.onActiveStatusCheck(data)}
            className={styles.checkbox}
          />
        </div>
      </div>
      <div className={styles.content}>
        <CustomNodeItem
          title={t('valueChainMap.profitCenterId')}
          value={data.uniqueIdentifier || '-'}
          isDisabled={isDisabled}
        />
        <CustomNodeItem
          title={t('valueChainMap.netBookValue')}
          value="-"
          isDisabled={isDisabled}
        />
        <CustomNodeItem
          title={t('valueChainMap.actualRevenue')}
          value={data.totalRevenueRolling12MActual
            ? `${data.totalRevenueRolling12MActual} (${data.totalRevenueRolling12MPercents}%)` : '-'}
          isDisabled={isDisabled}
        />
        <CustomNodeItem
          title={t('valueChainMap.actualGrossMargin')}
          value={data.totalGrossMarginRolling12MActual
            ? `${data.totalGrossMarginRolling12MActual} (${data.totalGrossMarginRolling12MPercents}%)` : '-'}
          isDisabled={isDisabled}
        />
        <CustomNodeItem
          title={t('valueChainMap.wipFinishedGoodsStock')}
          value={data.wipFinishedGoodsStock || '-'}
          isDisabled={isDisabled}
        />
        <Handle type="source" position={Position.Bottom} />
        <Handle type="target" position={Position.Top} />
      </div>
    </div>
  );
}

export default CustomNode;
