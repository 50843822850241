// Companies
export const COMPANIES_LIST = '/companies/list';
export const COMPANIES_CREATE = '/companies/create';
export const COMPANIES_DETAILS = '/companies/details/{companyId}';
export const COMPANIES_UPDATE_INFO = '/companies/update-short-info/{companyId}';
export const COMPANIES_LANGUAGES = '/companies/update-languages/{companyId}';
export const COMPANIES_ADMINS = '/companies/update-company-admins/{companyId}';
export const COMPANIES_UI_CONFIGURATION = '/companies/update-ui-configuration/{companyId}';
export const COMPANIES_PERMISSIONS = '/companies/permissions/{companyId}';
export const COMPANIES_SECURITY = '/companies/update-security/{companyId}';
export const COMPANIES_ACTIVATE = '/companies/activate/{companyId}';
export const COMPANIES_ARCHIVE = '/companies/archive/{companyId}';
export const COMPANIES_DE_ARCHIVE = '/companies/de-archive/{companyId}';
export const COMPANIES_DELETE = '/companies/delete-draft/{companyId}';

// Admin Users
export const ADMIN_USERS_DEACTIVATE_SECURITY = '/company-users/admin/{userId}/deactivate-security';

// Auth
export const AUTH_INVITE_USER = '/auth/abilities/invite';
export const AUTH_FORGOT_PASSWORD = '/auth/users/forgot-password';
export const RESEND_PASSWORD_LINK = '/auth/abilities/resend-password-link';
export const AUTH_CHANGE_PASSWORD = '/auth/users/change-password';
export const AUTH_RESEND_SMS = '/auth/mfa/resend-sms';
export const AUTH_VERIFY_TOKEN = '/auth/verify-token';
export const AUTH_CHECK_TOKEN = '/auth/check-token';

// Files
export const FILES_PRE_SIGNED = '/files/get-pre-signed-post';

// Company Users
export const COMPANY_ADMIN_USERS_LIST = '/company-users/abilities/list';
export const COMPANY_ADMIN_USERS_DELETE = '/company-users/abilities/{userId}/delete-draft';
export const COMPANY_ADMIN_USERS_DEACTIVATE = '/company-users/abilities/{userId}/deactivate';
export const COMPANY_ADMIN_USERS_ACTIVATE = '/company-users/abilities/{userId}/activate';
export const COMPANY_ADMIN_USERS_USER_DETAILS = '/company-users/abilities/{userId}/details';
export const COMPANY_ADMIN_USERS_USER_UPDATE = '/company-users/abilities/{userId}';
export const COMPANY_ADMIN_USERS_USER_CREATE = '/company-users/abilities/create';
export const COMPANY_ADMIN_USERS_PERMISSIONS = '/company-users/abilities/{userId}/permissions';
export const COMPANY_ADMIN_USERS_UPDATE_PERMISSIONS = '/company-users/abilities/{userId}/permissions';
export const COMPANY_ADMIN_COMPANIES_DETAILS = '/companies/abilities/details';
export const COMPANY_ADMIN_COMPANIES_UI_CONFIGURATION = 'companies/abilities/update-ui-configuration';

// User
export const USER_ME = '/company-users/me';
export const LOGIN_INFO = 'company-users/me/login-info';
export const USER_DEACTIVATE_SECURITY = '/company-users/me/deactivate-security';
export const USER_LOCALIZATION = '/company-users/me/localization';

// Data Sets
export const DATA_SETS_OWN_OPERATION_LIST = '/data-sets/own-operation/list';
export const DATA_SETS_SUPPLIER_LIST = '/data-sets/supplier/list';
export const DATA_SETS_CUSTOMER_LIST = '/data-sets/customer/list';
export const DATA_SETS_PRODUCT_LIST = '/data-sets/product/list';
export const DATA_SETS_PROFIT_CENTER_LIST = '/data-sets/profit-center/list';
export const DATA_SETS_PROFIT_CENTER_SHEET_EXAMPLE = '/data-sets/profit-center/sheet-example';
export const DATA_SETS_PROFIT_CENTER_IMPORT_RECORDS = '/data-sets/profit-center/import-records';
export const DATA_SETS_PROFIT_CENTER_ARCHIVE = '/data-sets/profit-center/archive';
export const DATA_SETS_OWN_OPERATION_ARCHIVE = '/data-sets/own-operation/archive';
export const DATA_SETS_SUPPLIER_ARCHIVE = '/data-sets/supplier/archive';
export const DATA_SETS_CUSTOMER_ARCHIVE = '/data-sets/customer/archive';
export const DATA_SETS_PRODUCT_ARCHIVE = '/data-sets/product/archive';
export const DATA_SETS_PROFIT_CENTER_DE_ARCHIVE = '/data-sets/profit-center/de-archive';
export const DATA_SETS_OWN_OPERATION_DE_ARCHIVE = '/data-sets/own-operation/de-archive';
export const DATA_SETS_SUPPLIER_DE_ARCHIVE = '/data-sets/supplier/de-archive';
export const DATA_SETS_CUSTOMER_DE_ARCHIVE = '/data-sets/customer/de-archive';
export const DATA_SETS_PRODUCT_DE_ARCHIVE = '/data-sets/product/de-archive';
export const DATA_SETS_GET_ENUM = '/data-sets/get-enum';
export const DATA_SETS_PROFIT_CENTER_CREATE = '/data-sets/profit-center/create';
export const DATA_SETS_PROFIT_CENTER_RECORD = '/data-sets/profit-center/get/{recordId}';
export const DATA_SETS_OWN_OPERATION_CREATE = '/data-sets/own-operation/create';
export const DATA_SETS_SUPPLIER_CREATE = '/data-sets/supplier/create';
export const DATA_SETS_PRODUCT_CREATE = '/data-sets/product/create';
export const DATA_SETS_OWN_OPERATION_RECORD = '/data-sets/own-operation/get/{recordId}';
export const DATA_SETS_SUPPLIER_RECORD = '/data-sets/supplier/get/{recordId}';
export const DATA_SETS_CUSTOMER_RECORD = '/data-sets/customer/get/{recordId}';
export const DATA_SETS_CUSTOMER_CREATE = '/data-sets/customer/create';
export const DATA_SETS_PRODUCT_RECORD = '/data-sets/product/get/{recordId}';
export const DATA_SETS_PROFIT_CENTER_RECORD_UPDATE = '/data-sets/profit-center/update';
export const DATA_SETS_OWN_OPERATION_RECORD_UPDATE = '/data-sets/own-operation/update';
export const DATA_SETS_SUPPLIER_RECORD_UPDATE = '/data-sets/supplier/update';
export const DATA_SETS_CUSTOMER_RECORD_UPDATE = '/data-sets/customer/update';
export const DATA_SETS_PRODUCT_UPDATE = '/data-sets/product/update';
export const DATA_SETS_OWN_OPERATION_IMPORT_RECORDS = '/data-sets/own-operation/import-records';
export const DATA_SETS_OWN_OPERATION_SHEET_EXAMPLE = '/data-sets/own-operation/sheet-example';
export const DATA_SETS_SUPPLIER_SHEET_EXAMPLE = '/data-sets/supplier/sheet-example';
export const DATA_SETS_CUSTOMER_SHEET_EXAMPLE = '/data-sets/customer/sheet-example';
export const DATA_SETS_PRODUCT_SHEET_EXAMPLE = '/data-sets/product/sheet-example';
export const DATA_SETS_SUPPLIER_IMPORT_RECORDS = '/data-sets/supplier/import-records';
export const DATA_SETS_CUSTOMER_IMPORT_RECORDS = '/data-sets/customer/import-records';
export const DATA_SETS_PRODUCT_IMPORT_RECORDS = '/data-sets/product/import-records';
export const DATA_SETS_NACE_CODES_TREE = '/data-sets/nace-codes-tree';
export const DATA_SETS_UNIQ_ORG_STRUCTURES = '/data-sets/get-uniq-org-structures';
export const DATA_SETS_UNIQ_GEO_STRUCTURES = '/data-sets/get-uniq-geo-structures';
export const DATA_SETS_CHANGE_ORG_STRUCTURE = '/data-sets/change-org-structure';
export const DATA_SETS_CHANGE_GEO_STRUCTURE = '/data-sets/change-geo-structure';
export const DATA_SETS_GET_UID_NAME_TUPLES = '/data-sets/get-uid-name-tuples';

// Weblate
export const RELEASE_BASE_TRANSLATION = '/weblate/release-base-translations';

// GeoLocations
export const GEO_LOCATIONS_COUNTRIES = '/geo-locations/countries';
export const GEO_LOCATIONS_STATES = '/geo-locations/states';
export const GEO_LOCATIONS_CITIES = '/geo-locations/cities';

// Value Chain Map
export const VALUE_CHAIN_MAP_UPDATE = '/value-chain-maps/update/{chainId}';
export const VALUE_CHAIN_MAP_DELETE = '/value-chain-maps/delete/{chainId}';
export const VALUE_CHAIN_MAP_LIST = '/value-chain-maps/list';
export const VALUE_CHAIN_MAP_CREATE = '/value-chain-maps/create';
export const VALUE_CHAIN_MAP_DUPLICATE = '/value-chain-maps/duplicate/{chainId}';
export const VALUE_CHAIN_MAP_ARCHIVE = '/value-chain-maps/archive/{chainId}';
export const VALUE_CHAIN_MAP_DE_ARCHIVE = '/value-chain-maps/de-archive/{chainId}';
export const VALUE_CHAIN_MAP_DETAILS = '/value-chain-maps/details/{chainId}';

// Business Model
export const BUSINESS_MODEL_STRUCTURE = '/business-model/structure/{valueChainMapId}';
export const BUSINESS_MODEL_STRUCTURE_STATUS_UPDATE = '/business-model/structure/branch/status';
export const BUSINESS_MODEL_STRUCTURE_ORG_PATH_UPDATE = '/business-model/structure/branch/org-path';
