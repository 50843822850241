import dayjs from 'dayjs';

import {
  DATE_CLOSED, DATE_OPENED, END_DATE,
  START_DATE,
} from '../constants/formFields';

import type { FieldValues, Path, PathValue } from 'react-hook-form';
import type { TDate } from '../components/DatePicker/interfaces/TDate';
import type { IUseDataSetsDate } from './interfaces/IUseDataSetsDate';

const useDataSetsDate = <T extends FieldValues>({ watch, setValue }: IUseDataSetsDate<T>) => {
  // TODO Remove all the anys
  const dateOpenedField = DATE_OPENED as Path<T>;
  const dateClosedField = DATE_CLOSED as Path<T>;

  const startDateValue = START_DATE as Path<T>;
  const endDateValue = END_DATE as Path<T>;

  const emptyValue = '' as PathValue<T, Path<T>>;

  const dateOpened = new Date(watch(dateOpenedField));
  const dateClosed = new Date(watch(dateClosedField));

  const startDate = new Date(watch(startDateValue));
  const endDate = new Date(watch(endDateValue));

  const onDateOpenedChange = (date: TDate) => {
    const dateDiff = dayjs(dateClosed).diff(date, 'days');
    if (dateDiff < 0) {
      setValue(dateClosedField, emptyValue);
    }
  };

  const onStartDateChange = (date: TDate) => {
    const dateDiff = dayjs(endDate).diff(date, 'days');
    if (dateDiff < 0) {
      setValue(endDateValue, emptyValue);
    }
  };

  return {
    onDateOpenedChange,
    dateOpened,
    onStartDateChange,
    startDate,
  };
};

export default useDataSetsDate;
