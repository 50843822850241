import React from 'react';
import { Controls, useReactFlow } from '@xyflow/react';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { clsx } from 'clsx';

import { REACT_FLOW } from '../../../../constants/general';

import type { IBottomRightControls } from './interfaces/IBottomRightControls';

import styles from './sass/BottomRightControls.module.scss';

function BottomRightControls({ currentZoom }: IBottomRightControls) {
  const { zoomIn, zoomOut } = useReactFlow();

  const onZoomIn = () => {
    zoomIn({ duration: REACT_FLOW.ZOOM_DURATION });
  };

  const onZoomOut = () => {
    zoomOut({ duration: REACT_FLOW.ZOOM_DURATION });
  };

  return (
    <Controls
      position="bottom-right"
      showFitView={false}
      showInteractive={false}
      showZoom={false}
    >
      <div className={styles.container}>
        <button
          type="button"
          onClick={onZoomOut}
          className={clsx(styles.btn, currentZoom === REACT_FLOW.MIN_ZOOM && styles.disabled)}
          disabled={currentZoom === REACT_FLOW.MIN_ZOOM}
        >
          <MinusIcon />
        </button>
        <div className={styles.divider} />
        <button
          type="button"
          onClick={onZoomIn}
          className={clsx(styles.btn, styles.plus, currentZoom === REACT_FLOW.MAX_ZOOM && styles.disabled)}
          disabled={currentZoom === REACT_FLOW.MAX_ZOOM}
        >
          <PlusIcon />
        </button>
      </div>
    </Controls>
  );
}

export default BottomRightControls;
