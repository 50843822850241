import React from 'react';
import { clsx } from 'clsx';

import type { ICustomNodeItem } from '../interfaces/ICustomNodeItem';

import styles from './sass/CustomNode.module.scss';

function CustomNodeItem({ title, value, isDisabled }: ICustomNodeItem) {
  return (
    <div className={styles.customNodeItem}>
      <p className={clsx(styles.title, {
        [styles.disabled]: isDisabled,
      })}
      >
        {title}
      </p>
      <p className={clsx(styles.value, {
        [styles.disabled]: isDisabled,
      })}
      >
        {value}
      </p>
    </div>
  );
}

export default CustomNodeItem;
