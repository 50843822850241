import React from 'react';
import { useTranslation } from 'react-i18next';

import { PROFIT_CENTERS_TYPES } from '../../../../../../constants/interfaces';

import styles from './sass/ColorsTip.module.scss';

function ColorsTip() {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <p>{t('valueChainMap.profitCentres')}</p>
      <ul className={styles.list}>
        {Object.entries(PROFIT_CENTERS_TYPES).map(([key, value]) => (
          <li key={`profitCenters_${key}`}>
            <div className={styles[value.replace(/\s+/g, '')]} />
            <p className={styles.text}>{t(`valueChainMap.${value.replace(/\s+/g, '')}`)}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ColorsTip;
