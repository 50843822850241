import React, { useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { Trans, useTranslation } from 'react-i18next';
import {
  getBackendOptions,
  MultiBackend, Tree,
} from '@minoru/react-dnd-treeview';
import { ReactComponent as AcrossIcon } from 'assets/icons/across.svg';
import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';
import { clsx } from 'clsx';
import Button from 'components/Button/Button';
import IconButton from 'components/IconButton/IconButton';
import Modal from 'components/Modal/Modal';
import Separator from 'components/Separator/Separator';
import { BUTTON_VARIANT, FILTER_TABS } from 'constants/interfaces';
import { ownOperationsFilterNav } from 'constants/navigationLists';
import {
  useChangeGeoStructuresMutation,
  useChangeOrgStructuresMutation, useLazyUniqGeoStructuresQuery, useLazyUniqOrgStructuresQuery,
  useUniqGeoStructuresQuery,
  useUniqOrgStructuresQuery,
} from 'store/slices/dataSets/apis/dataSetsApi';
import createTreeData from 'utils/createTreeData';
import getNewPathStructure from 'utils/getNewPathStructure';
import getParentIds from 'utils/getParentIds';

import { CustomDragPreview } from './CustomDragPreview';
import { CustomNode } from './CustomNode';

import type { DropOptions, NodeModel } from '@minoru/react-dnd-treeview';
import type IFiltersBar from './interfaces/IFiltersBar';
import type INodeModelData from './interfaces/INodeModelData';
import type IOwnOperationsFilterNav from './interfaces/IOwnOperationsFilterNav';

import styles from './scss/FiltersBar.module.scss';

function FiltersBar({
  control, onFilterApply, orgTypePrefix, setIsFiltersBarVisible, onFilterReset, geoTypePrefix,
}: IFiltersBar) {
  const { t } = useTranslation();
  const navList = ownOperationsFilterNav();
  const { data, isLoading } = useUniqOrgStructuresQuery();

  const { data: dataGeo, isLoading: isLoadingGeo } = useUniqGeoStructuresQuery();

  const [lazyUniqOrgStructures] = useLazyUniqOrgStructuresQuery();
  const [lazyUniqGeoStructures] = useLazyUniqGeoStructuresQuery();

  const [changeOrgStructures] = useChangeOrgStructuresMutation();
  const [changeGeoStructures] = useChangeGeoStructuresMutation();

  const uniqOrgStructures = data?.data || [];
  const uniqOrgStructuresTree = createTreeData(uniqOrgStructures);
  const [treeDataOrg, setTreeDataOrg] = useState<NodeModel<INodeModelData>[]>(uniqOrgStructuresTree);
  const [initialOpen, setInitialOpen] = useState<(string | number)[]>([]);

  const [currenDragItem, setCurrenDragItem] = useState<(DropOptions<INodeModelData> | undefined)>(undefined);

  const [isEdit, setIsEdit] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const uniqGeoStructures = dataGeo?.data || [];
  const uniqGeoStructuresTree = createTreeData(uniqGeoStructures);
  const [treeDataGeo, setTreeDataGeo] = useState<NodeModel<INodeModelData>[]>(uniqGeoStructuresTree);

  const [tab, setTab] = useState<IOwnOperationsFilterNav['key']>(FILTER_TABS.organizational);

  const isOrganizationalTab = tab === FILTER_TABS.organizational;

  const treeData = isOrganizationalTab ? treeDataOrg : treeDataGeo;
  const setTreeData = isOrganizationalTab ? setTreeDataOrg : setTreeDataGeo;

  useEffect(() => {
    if (tab === FILTER_TABS.organizational) {
      if (uniqOrgStructuresTree?.length > 0 && !isLoading && treeData.length === 0) {
        setTreeData(uniqOrgStructuresTree);
      }
    } else if (uniqGeoStructuresTree?.length > 0 && !isLoadingGeo && treeData.length === 0) {
      setTreeData(uniqGeoStructuresTree);
    }
  }, [uniqOrgStructuresTree, uniqGeoStructuresTree, isLoading, isLoadingGeo, treeData, setTreeData, tab]);

  const saveDropItem = () => {
    setIsConfirmationModalOpen(false);
    const { dragSource, dropTarget } = currenDragItem || {};

    const prevPrefix = dragSource?.data?.path;

    if (prevPrefix && dropTarget?.data && dragSource?.data?.path) {
      const path = getNewPathStructure(currenDragItem);
      if (isOrganizationalTab) {
        changeOrgStructures({ orgTypePrefix: prevPrefix, orgPath: path }).unwrap().then(() => {
          lazyUniqOrgStructures().unwrap().then((uniqOrgStructuresData) => {
            setTreeData(createTreeData(uniqOrgStructuresData.data));
          });
        }).catch(() => {});
      } else {
        changeGeoStructures({ geoTypePrefix: prevPrefix, geoPath: path }).unwrap().then(() => {
          lazyUniqGeoStructures().unwrap().then((uniqGeoStructuresData) => {
            setTreeData(createTreeData(uniqGeoStructuresData.data));
          });
        }).catch(() => {});
      }
    }
  };

  const handleDrop = (_: NodeModel<INodeModelData>[], dragItem: DropOptions<INodeModelData>) => {
    if (!isEdit) {
      return;
    }

    setCurrenDragItem(dragItem);
    setIsConfirmationModalOpen(true);
  };
  const isDisabled = (tab === FILTER_TABS.organizational && orgTypePrefix === '')
      || (tab === FILTER_TABS.geographical && geoTypePrefix === '');

  const currentData = tab === FILTER_TABS.organizational ? orgTypePrefix : geoTypePrefix;
  const currentItem = treeData.find((node) => node.data === currentData);

  const parentIds = useMemo(() => (currentItem ? getParentIds(currentItem, treeData) : []), [currentItem, treeData]);

  useEffect(() => {
    if (parentIds?.length > 0 && initialOpen.length === 0) {
      setInitialOpen(parentIds);
    }
  }, [parentIds, initialOpen, tab]);

  const onNavigate = (key: IOwnOperationsFilterNav['key']) => {
    setTab(key);
    setInitialOpen([]);
    setIsEdit(false);
  };

  const onCancel = () => {
    if (isEdit) {
      setIsEdit(false);
    } else {
      setIsFiltersBarVisible(false);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.contentWrapper}>
        <div className={isEdit ? styles.contentEdit : styles.content}>
          <div className={styles.header}>
            <div className={styles.menu}>
              <ul className={styles.list}>
                {navList.map(({
                  name,
                  key,
                }) => (
                  <li key={key}>
                    <div
                      role="button"
                      tabIndex={0}
                      className={clsx(styles.link, {
                        [clsx(styles.active, styles.active)]: key === tab,
                      })}
                      onClick={() => onNavigate(key)}
                      onKeyDown={() => {}}
                    >
                      <p>{name}</p>
                    </div>
                  </li>
                ))}
              </ul>
              <Separator className={styles.separator} />
            </div>
            {!isEdit ? (
              <>
                <div className={styles.actions}>
                  <Button
                    title={t('ownOperations.filter.resetFilters')}
                    startIcon={<AcrossIcon />}
                    variant="ghost"
                    onClick={onFilterReset}
                  />
                  {tab === FILTER_TABS.organizational && (
                  <IconButton
                    icon={<PenIcon />}
                    onClick={() => {
                      setIsEdit(true);
                    }}
                  />
                  )}
                </div>
                <Separator className={styles.headerSeparator} />
              </>
            ) : null}
          </div>
          <div className={styles.scrollContent}>
            <div className={styles.filter}>
              <DndProvider backend={MultiBackend} options={getBackendOptions()}>
                <Tree
                  initialOpen={initialOpen}
                  tree={treeData}
                  rootId={0}
                  render={(node: NodeModel<INodeModelData>, {
                    isOpen,
                    onToggle,
                  }) => (
                    <CustomNode
                      node={node}
                      isOpen={isOpen}
                      onToggle={onToggle}
                      control={control}
                      tab={tab}
                      isEdit={isEdit}
                    />
                  )}
                  dragPreviewRender={isEdit ? (monitorProps) => (
                    <CustomDragPreview monitorProps={monitorProps} />
                  ) : undefined}
                  onDrop={handleDrop}
                  classes={{
                    draggingSource: styles.draggingSource,
                    dropTarget: styles.dropTarget,
                  }}
                />
              </DndProvider>

            </div>
          </div>
        </div>

        <Modal
          open={isConfirmationModalOpen}
          title={t('common.changeOrgPathModalTitle')}
          description={(
            <Trans
              i18nKey="common.changeOrgPathModalDescription"
              t={t}
              values={{
                prevPath: currenDragItem?.dragSource?.data?.path,
                newPath: getNewPathStructure(currenDragItem),
              }}
              components={{
                b: <b />,
                br: <br />,
              }}
              tOptions={{ interpolation: { escapeValue: false } }}
            />
          )}
          confirmTitle={t('common.confirm')}
          closeTitle={t('common.cancel')}
          onConfirm={saveDropItem}
          onReject={() => { setIsConfirmationModalOpen(false); }}
        />

        <div className={styles.buttonsWrapper}>
          <Button
            className={styles.button}
            variant={BUTTON_VARIANT.cancel}
            title={t('common.cancel')}
            onClick={onCancel}
          />
          {!isEdit ? (
            <Button
              className={styles.button}
              title={t('common.apply')}
              onClick={onFilterApply}
              disabled={isDisabled}
            />
          ) : null}

        </div>
      </div>
    </div>
  );
}

export default FiltersBar;
