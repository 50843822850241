import { forwardRef } from 'react';
import { clsx } from 'clsx';

import type ITable from './interfaces/ITable';

import styles from './sass/Table.module.scss';

const Table = forwardRef<HTMLTableElement, ITable>(({ children, className }, ref) => (
  <div className={clsx(styles.root, className)}>
    <table className={styles.table} ref={ref}>
      {children}
    </table>
  </div>

));

export default Table;
