import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Panel,
  ReactFlow, useReactFlow,
} from '@xyflow/react';
import { ReactComponent as ProfitCenterIcon } from 'assets/icons/profitCenter.svg';

import DeactivationInfoModal from '../../../../../components/DeactivationModals/DeactivationInfoModal';
import DeactivationModal from '../../../../../components/DeactivationModals/DeactivationModal';
import Loader from '../../../../../components/Loader/Loader';
import { REACT_FLOW } from '../../../../../constants/general';
import { BUSINESS_STRUCTURE_MODALS } from '../../../../../constants/interfaces';
import deactivationInfoMock from '../../../../../mocks/deactivationInfo';
import ColorsTip from './components/ColorsTip';
import ConfirmStructureChangeModal from './components/ConfirmStructureChangeModal';
import CustomNode from './components/CustomNode';

import type { IBusinessStructureView } from './interfaces/IBusinessStructure';

import '@xyflow/react/dist/style.css';
import styles from './sass/BusinessStructureView.module.scss';

const edgeOptions = {
  animated: false,
  style: {
    stroke: '#5A5F60',
  },
};

const nodeTypes = {
  custom: CustomNode,
};

function BusinessStructureView({
  nodes, edges, updateEdgeStyles, onNodesChange, onEdgesChange, onConnect, onMoveEnd, isLoading,
  onDisableConfirm, closeModal, currentOpenedModal, onDragStart, onDragStop, onNodeDrag, onConfirmStructureChange,
  targetNode, draggingNode, onCancelStructureChange,
}: IBusinessStructureView) {
  const { t } = useTranslation();
  const { getZoom } = useReactFlow();

  return (
    <>
      <Panel className={styles.rightCenterPanel} position="bottom-right">
        <button
          onClick={() => {}}
          type="button"
          className={styles.profitCenterWrapper}
        >
          <ProfitCenterIcon width={24} height={24} />
          <p>{t('valueChainMap.profitCenter')}</p>
        </button>
      </Panel>
      <Panel position="bottom-left" className={styles.colorsTip}>
        <ColorsTip />
      </Panel>
      {isLoading ? <Loader isPageLoading fullLoaderClassName={styles.fullLoader} /> : null }
      <ReactFlow
        nodes={nodes}
        edges={updateEdgeStyles(edges)}
        nodeTypes={nodeTypes}
        defaultEdgeOptions={edgeOptions}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onMoveEnd={() => onMoveEnd(getZoom())}
        onConnect={onConnect}
        onInit={(rfInstance) => rfInstance.fitView()}
        defaultViewport={{
          x: 0,
          y: 0,
          zoom: REACT_FLOW.DEFAULT_ZOOM,
        }}
        minZoom={REACT_FLOW.MIN_ZOOM}
        onNodeDragStart={onDragStart}
        onNodeDragStop={onDragStop}
        onNodeDrag={onNodeDrag}
      />
      <DeactivationModal
        open={currentOpenedModal === BUSINESS_STRUCTURE_MODALS.CONFIRM_DISABLE}
        title={t('valueChainMap.disablingReasonTitle')}
        text={t('valueChainMap.disablingReasonDescription')}
        onReject={closeModal}
        onSubmit={onDisableConfirm}
        isLoading={isLoading}
      />
      <DeactivationInfoModal
        open={currentOpenedModal === BUSINESS_STRUCTURE_MODALS.DISABLED_INFO}
        title={t('valueChainMap.disabledBranchDetails')}
        deactivationInfo={deactivationInfoMock}
        onClose={closeModal}
        isLoading={isLoading}
      />
      <ConfirmStructureChangeModal
        isOpen={currentOpenedModal === BUSINESS_STRUCTURE_MODALS.CONFIRM_STRUCTURE_CHANGE}
        isLoading={isLoading}
        onReject={onCancelStructureChange}
        onHandleConfirm={onConfirmStructureChange}
        draggingNode={draggingNode}
        targetNode={targetNode}
      />
    </>
  );
}

export default BusinessStructureView;
