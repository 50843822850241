import type IInfoRow from '../interfaces/IInfoRow';

import styles from '../sass/InfoModal.module.scss';

export default function InfoRow({ label, value }: IInfoRow) {
  return (
    <div className={styles.row}>
      <span className={styles.label}>{label}</span>
      <span className={styles.value}>{value}</span>
    </div>
  );
}
