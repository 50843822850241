import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal/Modal';
import { DATE_FORMAT } from 'constants/general';
import dayjs from 'dayjs';

import InfoRow from './components/InfoRow';

import type IInfoModal from './interfaces/IInfoModal';

import styles from './sass/InfoModal.module.scss';

export default function InfoModal({
  open,
  onClose,
  archiveInfo,
  isLoading,
}: IInfoModal) {
  const { t } = useTranslation();
  const { date, reason, initiator } = archiveInfo;
  return (
    <Modal
      open={open}
      title={t('common.archivedVCDetails')}
      isLoading={isLoading}
      onClose={onClose}
      isButtonsVisible={false}
    >
      <div className={styles.container}>
        <InfoRow label={t('common.person')} value={`${initiator.firstName} ${initiator.lastName}`} />
        <InfoRow label={t('common.date')} value={dayjs(date).format(DATE_FORMAT)} />
        <InfoRow label={t('common.reason')} value={reason} />
      </div>
    </Modal>
  );
}
