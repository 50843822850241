import { UID_NAME_TUPLES, VALUE_CHAIN_SECTION_DEFAULT_VALUES } from 'constants/dataSets';
import {
  ADDRESS_BUILDING,
  ADDRESS_CITY, ADDRESS_COORDINATES,
  ADDRESS_COUNTRY, ADDRESS_POST_CODE, ADDRESS_STATE, ADDRESS_STREET,
  BUSINESS_UNIT_TYPE,
  CODE,
  DATE_CLOSED,
  DATE_OPENED,
  END_DATE,
  EQUITY_SHAREHOLDING,
  EU_TAXONOMY_CODE,
  FINANCIAL_CONTROL, KEY_SUPPLIER_TIER_1_TOTAL,
  KEY_SUPPLIER_TIER_1_TOTAL_CURRENCY,
  KEY_SUPPLIER_TIER_10_OF_TOTAL,
  NACE_CODE,
  NACE_SECTION,
  NAME,
  OWNERSHIP,
  PROFIT_CENTER_ID,
  SPEND,
  SPEND_CURRENCY,
  START_DATE,
  UNIQUE_IDENTIFIER, UPSTREAM_TITLE,
  VALUE_CHAIN_SECTION,
} from 'constants/formFields';
import { DATA_SETS_DATE_FORMAT } from 'constants/general';
import dayjs from 'dayjs';
import createUidNameTuplesOptions from 'utils/createUidNameTuplesOptions';

import getFormValue from '../getFormValue';
import getNaceLabel from '../getNaceLabel';

import type { IGetSupplierDefaultValues } from './interfaces/IGetSupplierDefaultValues';

function getSupplierDefaultValues({ values, naceCodeTreeData, profitCenterIds }: IGetSupplierDefaultValues) {
  const {
    name,
    code,
    addressBuilding,
    addressCity,
    addressCountry,
    addressPostCode,
    addressState,
    addressStreet,
    businessUnitType,
    dateClosed,
    dateOpened,
    naceCode,
    equityShareholding,
    euTaxonomyCode,
    financialControl,
    keySupplierTier1OfTotal,
    keySupplierTier1Total,
    ownership,
    spend,
    uniqueIdentifier,
    valueChainSection,
    upstreamTitle,
    naceSection,
    addressCoordinates,
    profitCenterId,
    startDate,
    endDate,
  } = values || {};

  const defaultOption = undefined;

  const profitCenterOptions = createUidNameTuplesOptions({
    uidNameTuples: profitCenterIds,
    values: profitCenterId || [],
    tuplesKey: UID_NAME_TUPLES.profitCenter,
  });

  const { naceSectionLabel, naceDvisions, naceCodeLabel } = getNaceLabel(
    { naceCode: naceCode || '', naceSection, naceCodeTreeData },
  );

  return {
    [NAME]: name || '',
    [CODE]: code || '',
    [UNIQUE_IDENTIFIER]: uniqueIdentifier || '',
    [VALUE_CHAIN_SECTION]: valueChainSection ? {
      value: valueChainSection, label: valueChainSection,
    } : { value: VALUE_CHAIN_SECTION_DEFAULT_VALUES.supplier, label: VALUE_CHAIN_SECTION_DEFAULT_VALUES.supplier },
    [UPSTREAM_TITLE]: upstreamTitle ? {
      value: upstreamTitle, label: upstreamTitle,
    } : defaultOption,
    [OWNERSHIP]: ownership ? {
      value: ownership, label: ownership,
    } : defaultOption,
    [EQUITY_SHAREHOLDING]: getFormValue({ value: equityShareholding }),
    [FINANCIAL_CONTROL]: getFormValue({ value: financialControl }),
    [BUSINESS_UNIT_TYPE]: businessUnitType ? {
      value: businessUnitType, label: businessUnitType,
    } : defaultOption,
    [DATE_OPENED]: dateOpened ? dayjs(dateOpened).format(DATA_SETS_DATE_FORMAT) : '',
    [DATE_CLOSED]: dateClosed ? dayjs(dateClosed).format(DATA_SETS_DATE_FORMAT) : '',
    [PROFIT_CENTER_ID]: profitCenterOptions?.length > 0 ? profitCenterOptions : [],
    [START_DATE]: startDate ? dayjs(startDate).format(DATA_SETS_DATE_FORMAT) : '',
    [END_DATE]: endDate ? dayjs(endDate).format(DATA_SETS_DATE_FORMAT) : '',
    [NACE_CODE]: naceCode ? {
      value: naceCode,
      label: naceCodeLabel || '',
    } : defaultOption,
    [EU_TAXONOMY_CODE]: euTaxonomyCode || '',
    [SPEND]: getFormValue({ value: spend?.amount }),
    [SPEND_CURRENCY]: spend?.currency ? {
      value: spend.currency, label: spend.currency,
    } : defaultOption,
    [KEY_SUPPLIER_TIER_1_TOTAL]: getFormValue({ value: keySupplierTier1Total?.amount }),
    [KEY_SUPPLIER_TIER_1_TOTAL_CURRENCY]: keySupplierTier1Total?.currency ? {
      value: keySupplierTier1Total.currency, label: keySupplierTier1Total.currency,
    } : defaultOption,
    [KEY_SUPPLIER_TIER_10_OF_TOTAL]: getFormValue({ value: keySupplierTier1OfTotal }),
    [NACE_SECTION]: naceSection ? {
      value: naceSection,
      label: naceSectionLabel || '',
      divisions: naceDvisions,
    } : defaultOption,
    [ADDRESS_COUNTRY]: addressCountry ? {
      value: addressCountry, label: addressCountry,
    } : defaultOption,
    [ADDRESS_STATE]: addressState ? {
      value: addressState, label: addressState,
    } : defaultOption,
    [ADDRESS_POST_CODE]: addressPostCode || '',
    [ADDRESS_CITY]: addressCity ? {
      value: addressCity, label: addressCity,
    } : defaultOption,
    [ADDRESS_STREET]: addressStreet || '',
    [ADDRESS_BUILDING]: addressBuilding || '',
    [ADDRESS_COORDINATES]: addressCoordinates || '',
  };
}

export default getSupplierDefaultValues;
