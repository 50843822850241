import { useEffect, useState } from 'react';
import { APPROXIMATE_HEIGHT_OF_DROPDOWN, DROPDOWN_DIRECTION } from 'constants/general';

import type IUseDropdownDirection from './interfaces/IUseDropdownDirection';

const useDropdownDirection = ({
  isMoreActionsModalOpen, modalRef, tableRef, heightOfDropdown,
}: IUseDropdownDirection) => {
  const [dropdownDirection, setDropdownDirection] = useState<keyof typeof DROPDOWN_DIRECTION>(DROPDOWN_DIRECTION.down);

  useEffect(() => {
    if (isMoreActionsModalOpen && modalRef.current) {
      const buttonRect = modalRef.current.getBoundingClientRect();
      const parentRect = tableRef?.current?.getBoundingClientRect();

      const bottomBoundary = parentRect ? parentRect.bottom : window.innerHeight;
      const topBoundary = parentRect ? parentRect.top : 0;

      const spaceBelow = bottomBoundary - buttonRect.bottom;
      const spaceAbove = buttonRect.top - topBoundary;

      if (spaceBelow < (heightOfDropdown || APPROXIMATE_HEIGHT_OF_DROPDOWN) && spaceAbove > spaceBelow) {
        setDropdownDirection(DROPDOWN_DIRECTION.up);
      } else {
        setDropdownDirection(DROPDOWN_DIRECTION.down);
      }
    }
  }, [heightOfDropdown, isMoreActionsModalOpen, modalRef, tableRef]);

  return {
    dropdownDirection,
  };
};

export default useDropdownDirection;
