import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as CheckmarkIcon } from 'assets/icons/checkmark.svg';
import { ReactComponent as UndoIcon } from 'assets/icons/undo.svg';

import Button from '../../../../components/Button/Button';
import IconButton from '../../../../components/IconButton/IconButton';
import StatusBadgeWithSelector from '../../../../components/StatusBadgeWithSelector/StatusBadgeWithSelector';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import {
  BUTTON_VARIANT, COMPANY_MAP_TABS, ICON_BUTTON_SIZES, TOOLTIP_POSITIONS,
} from '../../../../constants/interfaces';

import type { ITopCenterControls } from './interfaces/ITopCenterControls';

import styles from './sass/TopCenterControls.module.scss';

function TopCenterControls({
  onTabHandler, currentTab, onRenameHandler, data, isLoading, onChangeStatus,
}: ITopCenterControls) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={styles.header}>
      <div className={styles.leftPanel}>
        <div className={styles.leftContent}>
          <Button
            className={styles.button}
            variant={BUTTON_VARIANT.ghost}
            title={t('common.back')}
            startIcon={<ArrowLeftIcon />}
            onClick={() => navigate(-1)}
          />
          <div className={styles.divider} />
          {!isLoading && data ? (
            <>
              <Tooltip
                hint={t('valueChainMap.clickToRename')}
                position={TOOLTIP_POSITIONS.BOTTOM}
                className={styles.hintWrapper}
                hintClassName={styles.hint}
              >
                <div
                  onClick={onRenameHandler}
                  onKeyDown={(e) => { if (e.key === 'Enter') onRenameHandler(); }}
                  role="button"
                  tabIndex={0}
                >
                  <p className={styles.title}>
                    {data?.name}
                  </p>
                </div>
              </Tooltip>
              <StatusBadgeWithSelector
                selectedStatus={data.status}
                onStatusChange={onChangeStatus}
              />
            </>
          ) : null}
        </div>
      </div>
      <div className={styles.centerPanel}>
        <div className={styles.switcher}>
          <Button
            title={t('valueChainMap.businessStructure')}
            onClick={() => onTabHandler(COMPANY_MAP_TABS.BUSINESS_STRUCTURE)}
            variant={currentTab === COMPANY_MAP_TABS.BUSINESS_STRUCTURE ? BUTTON_VARIANT.primary : BUTTON_VARIANT.ghost}
          />
          <Button
            title={t('valueChainMap.valueChain')}
            onClick={() => onTabHandler(COMPANY_MAP_TABS.VALUE_CHAIN_MAP)}
            variant={currentTab === COMPANY_MAP_TABS.VALUE_CHAIN_MAP ? BUTTON_VARIANT.primary : BUTTON_VARIANT.ghost}
          />
        </div>
      </div>
      <div className={styles.rightPanel}>
        <div className={styles.rightContent}>
          {/* // For the future update
            <Tooltip hint={t('valueChainMap.showLog')} position={TOOLTIP_POSITIONS.BOTTOM}>
              <IconButton
                size={ICON_BUTTON_SIZES.large}
                icon={<HistoryIcon />}
                onClick={() => {}}
                className={styles.icon}
              />
            </Tooltip>

            <div className={styles.divider} />
          */}
          <IconButton
            size={ICON_BUTTON_SIZES.large}
            icon={<UndoIcon />}
            onClick={() => {}}
            className={styles.icon}
          />
          <IconButton
            size={ICON_BUTTON_SIZES.large}
            icon={<UndoIcon />}
            onClick={() => {}}
            className={styles.undoIcon}
            disabled
          />
          <div className={styles.divider} />
          <div className={styles.saveContainer}>
            <div className={styles.checkMarkContainer}>
              <CheckmarkIcon width={12} height={12} />
            </div>
            <p className={styles.saveTitle}>{t('valueChainMap.saved')}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopCenterControls;
