import './i18n';

import {
  createBrowserRouter, createRoutesFromElements, generatePath,
  Navigate, Route, RouterProvider,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ReactComponent as SendIcon } from 'assets/icons/send-rounded.svg';
import { ReactComponent as ToastErrorIcon } from 'assets/icons/toast-error.svg';
import { AppRoutes } from 'constants/AppRoutes';
import AuthLayout from 'layout/AuthLayout/AuthLayout';
import ClientProfileLayout from 'layout/ClientProfileLayout/ClientProfileLayout';
import CreateClientLayout from 'layout/CreateClientLayout/CreateClientLayout';
import PageProtected from 'layout/Page/PageProtected';
import ProfileLayout from 'layout/ProfileLayout/ProfileLayout';
import AuthenticationType from 'pages/AuthPages/AuthenticationType/AuthenticationType';
import Login from 'pages/AuthPages/Login/Login';
import NewPassword from 'pages/AuthPages/NewPassword/NewPassword';
import RecoverPassword from 'pages/AuthPages/RecoverPassword/RecoverPassword';
import RecoverPasswordSuccess from 'pages/AuthPages/RecoverPasswordSuccess/RecoverPasswordSuccess';
import VerificationCode from 'pages/AuthPages/VerificationCode/VerificationCode';
import VerificationSms from 'pages/AuthPages/VerificationSms/VerificationSms';
import VerificationSmsSetup from 'pages/AuthPages/VerificationSmsSetup/VerificationSmsSetup';
import Settings from 'pages/CompanyAdminPages/Settings/Settings';
import UserManagement from 'pages/CompanyAdminPages/UserManagement/UserManagement';
import ValueChainMap from 'pages/CompanyAdminPages/ValueChainMap/ValueChainMap';
import Clients from 'pages/SuperAdminPages/Clients/Clients';
import CompanyAdmin from 'pages/SuperAdminPages/CompanyAdmin/CompanyAdmin';
import CompanyInfo from 'pages/SuperAdminPages/CompanyInfo/CompanyInfo';
import CompanyProfile from 'pages/SuperAdminPages/CompanyProfile/CompanyProfile';
import CompanyUsers from 'pages/SuperAdminPages/CompanyUsers/CompanyUsers';
import ModuleManagement from 'pages/SuperAdminPages/ModuleManagement/ModuleManagement';
import Multilingual from 'pages/SuperAdminPages/Multilingual/Multilingual';
import Security from 'pages/SuperAdminPages/Security/Security';
import UIConfiguration from 'pages/SuperAdminPages/UICofiguration/UICofiguration';
import UserSecurity from 'pages/SuperAdminPages/UserSecurity/UserSecurity';
import Launchpad from 'pages/UserPages/Launchpad/Launchpad';
import PersonalInfo from 'pages/UserPages/PersonalInfo/PersonalInfo';
import Profile from 'pages/UserPages/Profile/Profile';
import ProfileSecurity from 'pages/UserPages/Profile/Security/Security';
import ProfilePassword from 'pages/UserPages/ProfilePassword/ProfilePassword';

import { NEW_CLIENT_ID } from './constants/general';
import DataSetsLayout from './layout/DataSetsLayout/DataSetsLayout';
import SettingsLayout from './layout/SettingsLayout/SettingsLayout';
import UserManagementLayout from './layout/UserManagementLayout/UserManagementLayout';
import AuthenticationSetup from './pages/AuthPages/AuthenticationSetup/AuthenticationSetup';
import DeactivatedUser from './pages/AuthPages/DeactivatedUser/DeactivatedUser';
import CompanyMap from './pages/CompanyAdminPages/CompanyMap/CompanyMap';
import CreateNewUser from './pages/CompanyAdminPages/CreateNewUser/CreateNewUser';
import Permissions from './pages/CompanyAdminPages/Permissions/Permissions';
import UserInfo from './pages/CompanyAdminPages/UserInfo/UserInfo';
import BaseMultilingual from './pages/SuperAdminPages/BaseMultilingual/BaseMultilingual';
import CreateCustomer from './pages/UserPages/CreateCustomer/CreateCustomer';
import CreateOwnOperations from './pages/UserPages/CreateOwnOperations/CreateOwnOperations';
import CreateProduct from './pages/UserPages/CreateProduct/CreateProduct';
import CreateProfitCentre from './pages/UserPages/CreateProfitCentre/CreateProfitCentre';
import CreateSupplier from './pages/UserPages/CreateSupplier/CreateSupplier';
import Customers from './pages/UserPages/Customers/Customers';
import OwnOperations from './pages/UserPages/OwnOperations/OwnOperations';
import Products from './pages/UserPages/Products/Products';
import ProfileLanguage from './pages/UserPages/ProfileLanguage/ProfileLanguage';
import ProfitCentres from './pages/UserPages/ProfitCentres/ProfitCentres';
import Suppliers from './pages/UserPages/Suppliers/Suppliers';
import AppWrapper from './AppWrapper';

import type { ReactElement } from 'react';

import './styles/global.scss';

function App(): ReactElement {
  const toastIcons = ({ type }: { type: string }) => {
    if (type === 'success') { return <SendIcon />; }
    if (type === 'error') { return <ToastErrorIcon />; }
    return undefined;
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<AppWrapper />}>
        <Route element={<AuthLayout />} path={AppRoutes.login}>
          <Route path={AppRoutes.login} element={<Login />} />
          <Route path={AppRoutes.authenticationType} element={<AuthenticationType />} />
          <Route path={AppRoutes.authenticationSetup} element={<AuthenticationSetup />} />
          <Route path={AppRoutes.verificationSmsSetup} element={<VerificationSmsSetup />} />

          <Route path={AppRoutes.verificationSms} element={<VerificationSms />} />
          <Route path={AppRoutes.verificationCode} element={<VerificationCode />} />
          <Route path={AppRoutes.recoverPassword} element={<RecoverPassword />} />
          <Route path={AppRoutes.recoverPasswordSuccess} element={<RecoverPasswordSuccess />} />
          <Route path={AppRoutes.newPassword} element={<NewPassword />} />
          <Route path={AppRoutes.recoverPasswordConfirm} element={<NewPassword />} />
          <Route path={AppRoutes.deactivated} element={<DeactivatedUser />} />
        </Route>
        <Route path={AppRoutes.home} element={<PageProtected redirectUri={AppRoutes.login} />}>
          <Route path={AppRoutes.launchpad}>
            <Route path={AppRoutes.launchpad} element={<Launchpad />} />

            <Route path={AppRoutes.launchpadSettings}>
              <Route path={AppRoutes.launchpadSettings} element={<Settings />} />
              <Route
                path={AppRoutes.userManagement}
                element={<UserManagementLayout />}
              >

                <Route
                  path={AppRoutes.userManagement}
                  element={<UserManagement />}
                  handle={{ skipBreadcrumbs: true }}
                />

                <Route path={AppRoutes.createNewUser} element={<CreateNewUser />} />
                <Route path={AppRoutes.userInfo}>
                  <Route path={AppRoutes.userInfo} handle={{ skipBreadcrumbs: true }} element={<UserInfo />} />
                  <Route path={AppRoutes.editUserInfo} element={<CreateNewUser />} />
                  <Route path={AppRoutes.editUserPermissions} element={<Permissions />} />
                  <Route path={AppRoutes.createUserPermissions} element={<Permissions isCreateFlow />} />
                </Route>
              </Route>
              <Route
                element={<SettingsLayout />}
                handle={{ skipBreadcrumbs: true }}
              >
                <Route path={AppRoutes.companyUiConfiguration} element={<UIConfiguration />} />
              </Route>
              <Route
                element={<DataSetsLayout />}
                handle={{ skipBreadcrumbs: true }}
              >
                <Route path={AppRoutes.ownOperations} element={<OwnOperations />} />
                <Route path={AppRoutes.suppliers} element={<Suppliers />} />
                <Route path={AppRoutes.customers} element={<Customers />} />
                <Route path={AppRoutes.products} element={<Products />} />
                <Route path={AppRoutes.profitCentres} element={<ProfitCentres />} />
                <Route path={AppRoutes.profitCentres}>
                  <Route path={AppRoutes.profitCentresCreate} element={<CreateProfitCentre />} />
                  <Route path={AppRoutes.profitCentresEdit} element={<CreateProfitCentre />} />
                </Route>
                <Route path={AppRoutes.ownOperations}>
                  <Route path={AppRoutes.ownOperationsCreate} element={<CreateOwnOperations />} />
                  <Route path={AppRoutes.ownOperationsEdit} element={<CreateOwnOperations />} />
                </Route>
                <Route path={AppRoutes.suppliers}>
                  <Route path={AppRoutes.supplierCreate} element={<CreateSupplier />} />
                  <Route path={AppRoutes.supplierEdit} element={<CreateSupplier />} />
                </Route>
                <Route path={AppRoutes.products}>
                  <Route path={AppRoutes.productsCreate} element={<CreateProduct />} />
                  <Route path={AppRoutes.productsEdit} element={<CreateProduct />} />
                </Route>
                <Route path={AppRoutes.customers}>
                  <Route path={AppRoutes.customerCreate} element={<CreateCustomer />} />
                  <Route path={AppRoutes.customerEdit} element={<CreateCustomer />} />
                </Route>
              </Route>
            </Route>
          </Route>

          <Route element={<ProfileLayout />} path={AppRoutes.profile}>
            <Route path={AppRoutes.profile} element={<Profile />} />
            <Route path={AppRoutes.personalInfo} element={<PersonalInfo />} />
            <Route path={AppRoutes.profilePassword} element={<ProfilePassword />} />
            <Route path={AppRoutes.profileSecurity} element={<ProfileSecurity />} />
            <Route path={AppRoutes.profileLanguage} element={<ProfileLanguage />} />
          </Route>

          <Route path={AppRoutes.clients}>
            <Route index path={AppRoutes.clients} element={<Clients />} />

            <Route element={<ClientProfileLayout />} handle={{ skipBreadcrumbs: true }}>
              <Route path={AppRoutes.clientProfile}>
                <Route path={AppRoutes.clientProfile} element={<CompanyProfile />} handle={{ skipBreadcrumbs: true }} />
                <Route path={AppRoutes.clientUsers}>
                  <Route path={AppRoutes.clientUsers} element={<CompanyUsers />} handle={{ skipBreadcrumbs: true }} />
                  <Route path={AppRoutes.clientUsersSecurity}>
                    <Route
                      path={AppRoutes.clientUsersSecurity}
                      element={<UserSecurity />}
                      handle={{ skipBreadcrumbs: true }}
                    />
                  </Route>
                </Route>
              </Route>
            </Route>

            <Route element={<CreateClientLayout />} path={AppRoutes.clientsCreate}>
              <Route
                index
                element={<Navigate to={generatePath(AppRoutes.companyInfo, { clientId: NEW_CLIENT_ID })} />}
              />
              <Route path={AppRoutes.companyInfo} element={<CompanyInfo />} />
              <Route path={AppRoutes.multiLingual} element={<Multilingual />} />
              <Route path={AppRoutes.companyAdmin} element={<CompanyAdmin />} />
              <Route path={AppRoutes.uiConfiguration} element={<UIConfiguration />} />
              <Route path={AppRoutes.moduleManagement} element={<ModuleManagement />} />
              <Route path={AppRoutes.security} element={<Security />} />
            </Route>
          </Route>

          <Route
            path={AppRoutes.valueChainMap}
            element={<ValueChainMap />}
            handle={{ skipBreadcrumbs: true }}
          />

          <Route element={<BaseMultilingual />} path={AppRoutes.baseMultilingual} />
          <Route element={<CompanyMap />} path={AppRoutes.companyMap} />

        </Route>
      </Route>,
    ),
  );

  return (
    <div>
      <RouterProvider router={router} />
      <ToastContainer
        stacked
        hideProgressBar
        icon={toastIcons}
      />
    </div>
  );
}

export default App;
