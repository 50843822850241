import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/query';
import { UID_NAME_TUPLES } from 'constants/dataSets';
import {
  useGetNaceCodeTreeQuery,
  useSupplierRecordQuery,
  useUidNameTuplesQuery,
} from 'store/slices/dataSets/apis/dataSetsApi';
import { selectUidNameTuples } from 'store/slices/dataSets/selectors';
import getSupplierDefaultValues from 'utils/formHelpers/getSupplierDefaultValues';
import { createSupplierSchema } from 'utils/validators';

import type { TCreateSupplierSchema } from 'utils/validators';
import type { ICreateSupplierForm } from '../interfaces/ICreateSupplierForm';

const useCreateSupplierForm = ({ recordId, isPageLoading }: ICreateSupplierForm) => {
  const fetchQuery = recordId ? {
    recordId,
  } : skipToken;
  const { data } = useSupplierRecordQuery(fetchQuery);

  const { data: naceCodeTreeData } = useGetNaceCodeTreeQuery();

  const { data: profitCenterIds, isLoading: isProfitCenterIdsLoading } = useUidNameTuplesQuery({
    types: [UID_NAME_TUPLES.profitCenter],
  }, {
    selectFromResult: (result) => ({ ...result, data: selectUidNameTuples(result.data) }),
  });

  const {
    control,
    handleSubmit,
    formState: { isDirty, isLoading },
    reset,
    setError,
    getValues,
    watch,
    setValue,
    resetField,
  } = useForm<TCreateSupplierSchema>({
    mode: 'onSubmit',
    resolver: yupResolver(createSupplierSchema),
    defaultValues: getSupplierDefaultValues({ values: data?.data, naceCodeTreeData, profitCenterIds }),
  });

  useEffect(() => {
    if (data?.data && !isPageLoading && !isProfitCenterIdsLoading) {
      reset(getSupplierDefaultValues(
        { values: data?.data, naceCodeTreeData, profitCenterIds },
      ));
    }
  }, [reset, data, isPageLoading, naceCodeTreeData, isProfitCenterIdsLoading, profitCenterIds]);

  const fieldsName = Object.keys(getValues());

  return {
    control,
    handleSubmit,
    isDirty,
    reset,
    isLoading,
    setError,
    fieldsName,
    watch,
    setValue,
    resetField,
    profitCenterIds,
  };
};

export default useCreateSupplierForm;
