export default {
  title: 'Customers',
  subTitle: 'Lorem ipsum dolor',
  totalPages: '{{offset}}-{{totalPages}} of {{total}}',
  archivingReasonTitle: 'Specify reason for archiving',
  archivingReasonDescription: 'Please provide a reason for archiving the selected record(s). You can restore '
        + 'them anytime.',
  archiveDetails: 'Archived record details',
  editInformation: 'Edit Information',
  addNewCustomer: 'Add New Customer',
  editCustomer: 'Edit Customer',
  fields: {
    customerName: 'Customer Name',
    customerCode: 'Customer Code',
    upstreamTier: 'Upstream Tier',
    uniqueIdentifier: 'Unique Identifier',
    profitCentreId: 'Profit Centre ID',
    valueChain: 'Value Chain Section',
    ownership: 'Ownership',
    equityShareholding: 'Equity Shareholding %',
    financialControl: 'Financial Control %',
    physicalOrLogical: 'Physical or Logical',
    dateOpened: 'Date Opened',
    dateClosed: 'Date Closed',
    priorityRating: 'Priority Rating',
    industry: 'Industry (NACE)',
    sector: 'Sector (NACE)',
    expectedGrowth: 'Expected Growth',
    totalRevenue: '% of Total Revenue (rolling 12 months)',
    actualRevenue: 'Actual Revenue (rolling 12 months) ',
    country: 'Country of Operation/Incorporation',
    state: 'State / Region / County',
    post: 'Post Code',
    town: 'Town / City',
    street: 'Street',
    building: 'Building Name or Number',
    coordinates: 'Coordinates',
    actions: 'Actions',
    expectedGrowthNext12M: 'Expected Growth / Decline % (in next 12 months)',
    startDate: 'Start Date',
    endDate: 'End Date',
    startDateIsRequired: 'Start Date is required if any financial field is filled',
  },
  selections: {
    general: 'General',
    details: 'Details',
    financials: 'Financials',
    address: 'Address',
  },
};
