import { useTranslation } from 'react-i18next';
import StatusBadgeWithSelector from 'components/StatusBadgeWithSelector/StatusBadgeWithSelector';
import TableCell from 'components/Table/TableCell';
import TableRow from 'components/Table/TableRow';
import { EXTEND_DATE_FORMAT } from 'constants/general';
import { VALUE_CHAIN_STATUS } from 'constants/interfaces';
import dayjs from 'dayjs';

import ValueChainMapTableActions from '../ValueChainMapTableActions/ValueChainMapTableActions';

import type { TValueChainStatus } from 'constants/interfaces';
import type IValueChainMapTableRow from '../../interfaces/IValueChainRow';

import styles from './scss/ValueChainMapTableRow.module.scss';

function ValueChainMapTableRow({
  valueChainItem, onChangeStatus, onRenameValueChain, onDeleteValueChain, onEditValueChain, tableRef,
  onArchiveValueChain, onDeArchiveValueChain, onDublicateValueChain,
}: IValueChainMapTableRow) {
  const { t } = useTranslation();
  return (
    <TableRow>
      <TableCell className={styles.nameCell}>
        <p className={styles.clientName}>
          {valueChainItem.name}
        </p>
      </TableCell>
      <TableCell>
        <p>{dayjs(valueChainItem.createdAt).format(EXTEND_DATE_FORMAT)}</p>
        <p className={styles.adminName}>
          {t('by')}
          {` ${valueChainItem.createdBy.firstName} ${valueChainItem.createdBy.lastName}`}
        </p>
      </TableCell>
      <TableCell>
        <p>{dayjs(valueChainItem.updatedAt).format(EXTEND_DATE_FORMAT)}</p>
        <p className={styles.adminName}>
          {t('by')}
          {` ${valueChainItem.updatedBy.firstName} ${valueChainItem.updatedBy.lastName}`}
        </p>
      </TableCell>
      <TableCell>
        <StatusBadgeWithSelector
          selectedStatus={valueChainItem.status}
          onStatusChange={(status: TValueChainStatus) => onChangeStatus(valueChainItem.id, status)}
          tableRef={tableRef}
          archiveInfo={valueChainItem.latestArchivation}
        />
      </TableCell>
      <TableCell>
        <ValueChainMapTableActions
          valueChainItemId={valueChainItem.id}
          status={valueChainItem.status}
          onHandleEdit={() => onEditValueChain(valueChainItem.id)}
          isArchived={valueChainItem.status === VALUE_CHAIN_STATUS.archived}
          onRenameValueChain={onRenameValueChain}
          onDeleteValueChain={() => onDeleteValueChain(valueChainItem.id)}
          valueChainName={valueChainItem.name}
          tableRef={tableRef}
          onArchiveValueChain={onArchiveValueChain}
          onDeArchiveValueChain={onDeArchiveValueChain}
          onDublicateValueChain={onDublicateValueChain}
        />
      </TableCell>
    </TableRow>
  );
}

export default ValueChainMapTableRow;
