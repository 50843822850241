import React from 'react';
import {
  Background, BackgroundVariant, Panel, ReactFlowProvider,
} from '@xyflow/react';

import RenameModal from '../../../components/RenameModal/RenameModal';
import { COMPANY_MAP_MODALS, COMPANY_MAP_TABS } from '../../../constants/interfaces';
import BottomRightControls from './components/BottomRightControls';
import BusinessStructure from './components/BusinessStructure/BusinessStructure';
import TopCenterControls from './components/TopCenterControls';

import type { ICompanyMap } from './interfaces/ICompanyMap';

import styles from './sass/CompanyMapView.module.scss';

function CompanyMapView({
  currentTab, onTabHandler, onRenameHandler, activeModal, closeModal, onRenameSubmit, valueChainMapId,
  currentZoom, onMoveEnd, data, isLoading, onChangeStatus,
}: ICompanyMap) {
  return (
    <div className={styles.container}>
      <ReactFlowProvider>
        <Background variant={BackgroundVariant.Cross} gap={60} color="#DBDDDE" size={20} />
        <BottomRightControls currentZoom={currentZoom} />
        <Panel position="top-center" className={styles.topCenterControls}>
          <TopCenterControls
            onTabHandler={onTabHandler}
            currentTab={currentTab}
            onRenameHandler={onRenameHandler}
            data={data}
            isLoading={isLoading}
            onChangeStatus={onChangeStatus}
          />
        </Panel>
        {currentTab === COMPANY_MAP_TABS.BUSINESS_STRUCTURE && (
          <BusinessStructure valueChainMapId={valueChainMapId} onMoveEnd={onMoveEnd} />
        )}
      </ReactFlowProvider>
      {data ? (
        <RenameModal
          open={activeModal === COMPANY_MAP_MODALS.RENAME}
          onSubmit={onRenameSubmit}
          onReject={closeModal}
          name={data.name}
        />
      ) : null}
    </div>
  );
}

export default CompanyMapView;
