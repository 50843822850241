import Date from 'react-datepicker';
import { clsx } from 'clsx';
import dayjs from 'dayjs';

import type IDatePicker from './interfaces/IDatePicker';

import 'react-international-phone/style.css';
import styles from './scss/DatePicker.module.scss';

function DatePicker({
  name, label, value, onChange, placeholder,
  className, error, minDate, required, maxDate,
}: IDatePicker) {
  const classes = clsx(styles.root, className, {
    [styles.rootError]: error,
  });

  return (
    <div className={classes}>
      <label htmlFor={name}>
        {required ? `${label}*` : label}
      </label>
      <Date
        name={name}
        onChange={onChange}
        selected={dayjs(value).isValid() ? dayjs(value)?.toDate() : null}
        placeholderText={placeholder}
        minDate={minDate}
        maxDate={maxDate}
      />
      {error ? <p className={styles.error}>{error}</p> : null}
    </div>
  );
}

export default DatePicker;
