import { createSlice } from '@reduxjs/toolkit';

import type ILocalStorage from './interfaces/ILocalStorage';

export const initialState: ILocalStorage = {
  processingValuesChainMapId: [],
};

const localStorageSlice = createSlice({
  name: 'localStorage',
  initialState,
  reducers: {
    setProcessingValuesChainMapId: (state, action) => {
      state.processingValuesChainMapId = [...state.processingValuesChainMapId, action.payload];
    },
    removeProcessingValuesChainMapId: (state, action) => {
      state.processingValuesChainMapId = state.processingValuesChainMapId.filter((id) => id !== action.payload);
    },
  },
});

export const { setProcessingValuesChainMapId, removeProcessingValuesChainMapId } = localStorageSlice.actions;

export default localStorageSlice.reducer;
