import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { REACT_FLOW } from '../../../constants/general';
import {
  COMPANY_MAP_MODALS, COMPANY_MAP_TABS, type TCompanyMapModal, type TCompanyMapTab,
  type TValueChainStatus,
} from '../../../constants/interfaces';
import i18n from '../../../i18n';
import {
  useGetValueChainMapDetailsQuery,
  useUpdateValueChainItemMutation,
} from '../../../store/slices/valueChainMap/apis/valueChainMapApi';
import CompanyMapView from './CompanyMapView';

function CompanyMap() {
  const [currentTab, setCurrentTab] = useState<TCompanyMapTab>(COMPANY_MAP_TABS.BUSINESS_STRUCTURE);
  const [activeModal, setActiveModal] = useState<TCompanyMapModal | undefined>(undefined);
  const [currentZoom, setCurrentZoom] = useState<number>(REACT_FLOW.MIN_ZOOM);

  const { valueChainMapId } = useParams();

  const { data, isLoading } = useGetValueChainMapDetailsQuery({ chainId: valueChainMapId || '' });

  const [updateValueChainItem] = useUpdateValueChainItemMutation();

  const onTabHandler = (tab: TCompanyMapTab) => {
    setCurrentTab(tab);
  };

  const onRenameHandler = () => {
    setActiveModal(COMPANY_MAP_MODALS.RENAME);
  };

  const closeModal = () => {
    setActiveModal(undefined);
  };

  const onRenameSubmit = (name: string) => {
    updateValueChainItem({ chainId: Number(valueChainMapId), name })
      .unwrap()
      .then(() => toast.success(i18n.t('notifications.valueChainMapHasBeenRenamed')));
    setActiveModal(undefined);
  };

  const onChangeStatus = (status: TValueChainStatus) => {
    updateValueChainItem({ chainId: Number(valueChainMapId), status })
      .unwrap()
      .then(() => toast.success(i18n.t('notifications.statusHasBeenUpdated')));
  };

  const onMoveEnd = (zoom: number) => {
    setCurrentZoom(zoom);
  };

  return (
    <CompanyMapView
      currentTab={currentTab}
      onTabHandler={onTabHandler}
      activeModal={activeModal}
      onRenameHandler={onRenameHandler}
      closeModal={closeModal}
      onRenameSubmit={onRenameSubmit}
      valueChainMapId={valueChainMapId}
      currentZoom={currentZoom}
      onMoveEnd={onMoveEnd}
      data={data?.data}
      isLoading={isLoading}
      onChangeStatus={onChangeStatus}
    />
  );
}

export default CompanyMap;
