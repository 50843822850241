import { useState } from 'react';
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as ProcessingIcon } from 'assets/icons/processing.svg';
import InfoModal from 'components/InfoModal/InfoModal';
import { VALUE_CHAIN_STATUS } from 'constants/interfaces';
import { VALUE_CHAIN_STATUSES } from 'constants/valueChain';
import useDropdownDirection from 'hooks/useDropdownDirection';
import useOutsideClick from 'hooks/useOutsideClick';

import type { TValueChainStatus } from 'constants/interfaces';
import type IStatusBadgeWithSelector from './interfaces/IStatusBadgeWithSelector';

import styles from './scss/StatusBadgeWithSelector.module.scss';

export default function StatusBadgeWithSelector({
  selectedStatus,
  onStatusChange,
  tableRef,
  archiveInfo,
}: IStatusBadgeWithSelector) {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const modalRef = useOutsideClick(() => setIsDropdownVisible(false));
  const { dropdownDirection } = useDropdownDirection({ isMoreActionsModalOpen: isDropdownVisible, modalRef, tableRef });

  const selectHandler = (status: TValueChainStatus) => {
    onStatusChange(status);
    setIsDropdownVisible(false);
  };

  const statusesWithoutArchived = Object.values(VALUE_CHAIN_STATUS)
    .filter((status) => (status !== VALUE_CHAIN_STATUS.archived && status !== VALUE_CHAIN_STATUS.processing));

  const isArchived = selectedStatus === VALUE_CHAIN_STATUS.archived;
  const isProcessing = selectedStatus === VALUE_CHAIN_STATUS.processing;

  return (
    <div className={styles.selectorContainer} ref={modalRef}>
      <div
        className={`${styles.status} ${styles[selectedStatus]}`}
        onClick={() => setIsDropdownVisible(!isArchived && !isProcessing ? !isDropdownVisible : false)}
        aria-hidden="true"
      >
        {isProcessing ? <ProcessingIcon className={styles.processingIcon} /> : null}
        <span>{VALUE_CHAIN_STATUSES[selectedStatus]?.text}</span>
        {(!isArchived && !isProcessing) ? (
          <ChevronIcon
            className={`${styles.arrowIcon} ${isDropdownVisible ? styles.active : ''}`}
          />
        ) : null}
      </div>
      {isArchived ? <InfoIcon className={styles.infoIcon} onClick={() => setIsInfoModalOpen(true)} /> : null}
      {isDropdownVisible ? (
        <div className={`${styles.statusDropdown} ${styles[dropdownDirection]}`}>
          {
          Object.values(statusesWithoutArchived).map((status) => (
            <div
              key={status}
              onClick={() => selectHandler(status)}
              aria-hidden="true"
              className={`${styles.status} ${styles[status]}`}
            >
              <span>{VALUE_CHAIN_STATUSES[status]?.text}</span>
            </div>
          ))
        }
        </div>
      ) : null}
      {(isInfoModalOpen && archiveInfo) ? (
        <InfoModal
          archiveInfo={archiveInfo}
          onClose={() => setIsInfoModalOpen(false)}
          isLoading={false}
          open={isInfoModalOpen}
        />
      ) : null}
    </div>
  );
}
