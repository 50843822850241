import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import mainApi from './apis/mainApi';
import translatesMiddleware from './middleware/translates';
import auth from './slices/auth/slice';
import companies from './slices/companies/slice';
import companyAdminUsers from './slices/companyAdminUsers/slice';
import dataSets from './slices/dataSets/slice';
import general from './slices/general/slice';
import localStorage from './slices/localStorage/slice';
import theme from './slices/theme/slice';
import translates from './slices/translates/slice';
import valueChainMap from './slices/valueChainMap/slice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['auth', 'theme', 'localStorage'],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    [mainApi.reducerPath]: mainApi.reducer,
    auth,
    translates,
    theme,
    companies,
    general,
    companyAdminUsers,
    dataSets,
    valueChainMap,
    localStorage,
  }),
);

export function makeStore(preloadedState = {}) {
  return configureStore({
    reducer: persistedReducer,
    preloadedState: Object.keys(preloadedState).length ? preloadedState : undefined,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat([
        mainApi.middleware,
        translatesMiddleware,
      ]),
  });
}

export const store = makeStore();

setupListeners(store.dispatch);

export const persistor = persistStore(store);
