export default {
  pageTitle: 'Value Chain Map',
  addNewValueChainMap: 'Add a new value chain map',
  createNewValueChainMap: 'Create new value chain map',
  searchByName: 'Search by name',
  filterByStatus: 'Filter by status',
  thereAreNoValueChanMaps: 'There are no Value Chain Maps yet',
  name: 'Name',
  createdDate: 'Created',
  lastModifiedAt: 'Last modified',
  status: 'Status',
  action: 'Actions',
  current: 'Current',
  inProgress: 'In progress',
  underReview: 'Under review',
  readyForReview: 'Ready for review',
  archived: 'Archived',
  edit: 'Edit',
  delete: 'Delete',
  copyOf: 'Copy of',
  rename: 'Rename',
  dublicate: 'Duplicate',
  archive: 'Archive',
  restore: 'Restore',
  processing: 'Processing...',
  actualRevenue: 'actual revenue (rolling 12 months): ',
  actualGrossMargin: 'actual gross margin (rolling 12 months): ',
  wipFinishedGoodsStock: 'WIP & Finished Goods Stock',
  profitCentres: 'Profit Centres: ',
  Subsidiary: 'Subsidiary',
  JV: 'JV',
  AssociateSubsidiary: 'Associate Subsidiary',
  BusinessUnit: 'Business unit',
  Division: 'Division',
  businessStructure: 'Business Structure',
  valueChain: 'Value Chain',
  saved: 'Saved',
  showLog: 'Show change log',
  clickToRename: 'Click to rename',
  profitCenter: 'Profit Centre',
  profitCenterId: 'Profit Centre ID',
  netBookValue: 'Allocated Net Book Value',
  disablingReasonTitle: 'Specify reason for disabling',
  disablingReasonDescription: 'Please provide a reason for disabling the selected branch.',
  disabledBranchDetails: 'Disabled branch details',
};
