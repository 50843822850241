import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Modal from 'components/Modal/Modal';

import type { IConfirmStructureChangeModal } from '../interfaces/IConfirmStructureChangeModal';

function ConfirmStructureChangeModal({
  isOpen, onHandleConfirm, onReject, isLoading, draggingNode, targetNode,
}: IConfirmStructureChangeModal) {
  const { t } = useTranslation();

  return (
    <Modal
      open={isOpen}
      title={t('common.changeOrgPathModalTitle')}
      description={(
        <Trans
          i18nKey="common.changeOrgPathModalDescription"
          t={t}
          values={{
            prevPath: draggingNode?.data.orgPath,
            newPath: targetNode?.data.orgPath,
          }}
          components={{
            b: <b />,
            br: <br />,
          }}
          tOptions={{ interpolation: { escapeValue: false } }}
        />
      )}
      confirmTitle={t('common.confirm')}
      closeTitle={t('common.cancel')}
      onReject={onReject}
      onConfirm={onHandleConfirm}
      isLoading={isLoading}
    />
  );
}

export default ConfirmStructureChangeModal;
