import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../../types/TStore';
import type ILocalStorage from './interfaces/ILocalStorage';

const selectLocalStorage = (state: RootState): ILocalStorage => state.localStorage;

const selectProcessingValuesChainMapId = createSelector(
  selectLocalStorage,
  (localStorage) => localStorage.processingValuesChainMapId,
);

export default selectProcessingValuesChainMapId;
