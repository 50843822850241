import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { clsx } from 'clsx';

import type { IEmptyList } from './interfaces/IEmptyList';

import styles from './scss/EmptyList.module.scss';

function EmptyList({
  text, children, isWarning,
}: IEmptyList) {
  const { t } = useTranslation();
  const defaultEmptyText = (
    <Trans
      i18nKey="common.noResults"
      t={t}
      components={{
        br: <br />,
      }}
    />
  );
  return (
    <div className={clsx(styles.root, {
      [styles.warning]: isWarning,
    })}
    >
      <InfoIcon />
      <p className={styles.text}>
        {text || defaultEmptyText}
      </p>
      {children}
    </div>
  );
}

export default EmptyList;
